class Api {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async createLead(endpoint, values) {
    window.location.search
      .replace("?", "")
      .split("&")
      .map((str) => {
        if (str) {
          const elem = str.split("=");
          if (elem) values[elem[0]] = elem[1];
        }
      });
    try {
      const res = await fetch(`${this.baseUrl}${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (!res.ok) throw res.status;
      const { customer } = await res.json();
      return { ...values, customerId: customer };
    } catch (error) {
      throw "Something wrong. Please try again";
    }
  }

  async updateLead(endpoint, values) {
    const { customerId, ...restValues } = values;
    try {
      const res = await fetch(`${this.baseUrl}${endpoint}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          customer: customerId,
        },
        body: JSON.stringify(restValues),
      });
      if (!res.ok) throw res.status;
    } catch (error) {
      throw "Something wrong. Please try again";
    }
  }

  async updatePhoneNumber(endpoint, values) {
    const { customerId, phone } = values;
    try {
      const res = await fetch(`${this.baseUrl}${endpoint}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          customer: customerId,
        },
        body: JSON.stringify({ phone }),
      });
      if (!res.ok) throw res.status;
      return { phone };
    } catch (error) {
      throw "Something wrong. Please try again";
    }
  }

  async checkSmsCode(endpoint, values) {
    const { customerId, ...restValues } = values;
    try {
      const res = await fetch(`${this.baseUrl}${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          customer: customerId,
        },
        body: JSON.stringify(restValues),
      });
      if (!res.ok) throw res;
    } catch (error) {
      if (error.status === 400) {
        const validContentType = error.headers
          .get("content-type")
          .includes("application/json");
        if (validContentType) {
          const errMessage = await error.json();
          throw `${errMessage.msg}`;
        }
      }
      throw "Something wrong. Please try again";
    }
  }

  async getSmsCode(endpoint, values) {
    const { customerId } = values;
    try {
      const res = await fetch(`${this.baseUrl}${endpoint}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          customer: customerId,
        },
      });
      if (!res.ok) throw res.status;
    } catch (error) {
      throw "Something wrong. Please try again";
    }
  }
}

export default new Api("https://apidispatch.trucking42.com");
