import { createElement, useRef, useState } from "react";

import { AnimatePresence } from "framer-motion";
import AnimatedScreen from "components/AnimatedScreen";

import { Logo } from "assets/icons";
import { MainContainer } from "styles/app.style";
import FirstScreenCopy from "screens/FirstScreen";
// import FirstScreenOriginal from "screens/FirstScreenOriginal";
import SecondScreen from "screens/SecondScreen";
// import ThirdScreen from "screens/ThirdScreen";
// import FourthScreen from "screens/FourthScreen";
// import FifthScreen from "screens/FifthScreen";
// import SixthScreen from "screens/SixthScreen";
// import SeventhScreen from "screens/SeventhScreen";
// import EighthScreen from "screens/EighthScreen";
// import NinthScreen from "screens/NinthScreen";
// import TenthScreen from "screens/TenthScreen";
// import EleventhScreen from "screens/EleventhScreen";
import TermsAndConditions from "screens/TermsAndConditions";
import ConditionalScreen from "screens/ConditionalScreen";

const comp = [
  // FirstScreenOriginal,
  FirstScreenCopy,
  SecondScreen,
  ConditionalScreen,
  // ThirdScreen,
  // EighthScreen, // 8
  // FifthScreen,
  // SixthScreen,
  // SeventhScreen,
  // FourthScreen, // 4
  // NinthScreen,
  // TenthScreen,
  // EleventhScreen,
  TermsAndConditions,
];

function App() {
  const [step, setStep] = useState(0);
  const customerRef = useRef({
    fullName: "",
    phone: "",
    email: "",
    customerId: "",
  });

  const setCustomer = (values) => {
    customerRef.current = { ...customerRef.current, ...values };
  };
  const getCustomer = () => customerRef.current;
  const handleNextStep = (step) => {
    setStep((prevStep) => (typeof step === "number" ? step : prevStep + 1));
  };

  return (
    <MainContainer className={step === 2 ? "extra-class" : null}>
      {/* <div className={step === 0 ? "primary-logo" : "secondary-logo"}> */}
      <div className={"secondary-logo"}>
        <Logo />
      </div>
      <AnimatePresence>
        <AnimatedScreen screenKey={step}>
          {createElement(comp[step], {
            onNextStep: handleNextStep,
            customer: getCustomer(),
            setCustomer: setCustomer,
          })}
        </AnimatedScreen>
      </AnimatePresence>
    </MainContainer>
  );
}

export default App;
