import { Formik } from "formik";
import { getCustomerFormSchema } from "validations";
import { useSubmitForm } from "hooks/useSubmitform";

import SendButton from "components/SendButton";
import { PhoneField, TextField } from "components/FormFields";

import styled from "styles";
const FirstScreenRoot = styled.div`
  form {
    .copyright {
      margin-top: 1.2rem;
      font-size: 1.4rem;
      line-height: 140%;
      letter-spacing: -0.392308px;
      color: var(--font-tertiary-color);
      span {
        font-size: 1.4rem;
        color: #0058d7;
        cursor: pointer;
      }
    }
  }
  .form-row {
    margin-bottom: 3rem;
  }
`;

const FirstScreen = ({ onNextStep, setCustomer }) => {
  const [{ loading, error }, handleSubmit] = useSubmitForm({
    method: "createLead",
    path: "/lead",
    callback: (values) => {
      setCustomer(values);
      onNextStep();
    },
  });

  // const handleSubmit = (values) => {
  //   console.log(values);
  // };

  return (
    <FirstScreenRoot>
      <h1 className="title">GET A LIST OF ACTIVE FAKE BROKERS</h1>
      <p className="subtitle" style={{ marginBottom: "0.8rem" }}>
        DO NOT FALL VICTIM TO DECEPTION!
      </p>
      <p className="description" style={{ marginBottom: "3rem" }}>
        This list will help to avoid scammers and save your money.
      </p>

      <Formik
        initialValues={{
          fullName: "",
          phone: "",
          email: "",
        }}
        validationSchema={getCustomerFormSchema()}
        onSubmit={handleSubmit}
      >
        {({ values, handleSubmit, setFieldValue, handleBlur }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <TextField name="fullName" placeholder="Full name" />
            </div>
            <div className="form-row">
              <PhoneField
                name="phone"
                value={values.phone}
                placeholder="Phone"
                onChange={setFieldValue}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-row" style={{ marginBottom: "4.8rem" }}>
              <TextField name="email" placeholder="Email" />
            </div>

            <SendButton
              error={error}
              disabled={loading}
              type="submit"
              conditionalClasses={loading ? "inactive" : ""}
              id="step-1"
            >
              Get for Free
            </SendButton>
            <p className="copyright">
              By confirm you are agreeing to our{" "}
              <span onClick={() => onNextStep(3)}>Terms and Conditions</span>
            </p>
          </form>
        )}
      </Formik>
      {/* <button style={{ color: "#333" }} onClick={onNextStep}>
        NEXT STEP
      </button> */}
    </FirstScreenRoot>
  );
};

export default FirstScreen;
