import * as Yup from "yup";

export const getCustomerFormSchema = () => {
  return Yup.object().shape({
    fullName: Yup.string()
      .trim()
      .required("Name is required")
      .matches(/^[a-z-\s]+$/i, "Invalid name: only Latin letters allowed"),
    phone: Yup.string().required("Mobile phone is required"),
    email: Yup.string()
      .trim()
      .required("Enter email address")
      .email("Invalid email"),
  });
};

export const getPhoneSchema = () => {
  return Yup.object().shape({
    phone: Yup.string().required("Mobile phone is required"),
  });
};

export const getOtherInputSchema = () => {
  return Yup.object().shape({
    other: Yup.string().max(50, "Input 'other' must be under 50 characters"),
  });
};
