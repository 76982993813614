import { SmallLeftArrowIcon } from "assets/icons";
import styled from "styles";
const TermsAndConditionsRoot = styled.div`
  position: relative;
  .terms-title {
    margin-bottom: 2rem;
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.015em;
    color: #121316;
  }
  .terms-point {
    margin-bottom: 1.6rem;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 120%;
    letter-spacing: -0.015em;
    color: #212121;
  }
  .terms-desc {
    margin-bottom: 1.6rem;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #212121;
  }
  .terms-text {
    margin-bottom: 1.6rem;
    font-size: 1.6rem;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #606060;
    strong {
      font-weight: 500;
      color: #212121;
    }
    a {
      color: #0058d7;
      text-decoration: underline;
    }
  }
  ul {
    list-style: disc;
    list-style-position: inside;
    margin-bottom: 1.6rem;
    li {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #161617;
    }
  }

  & > button {
    display: flex;
    align-items: center;
    position: absolute;
    width: 6.4rem;
    height: 2.9rem;
    top: -6.5rem;
    left: 0;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.03em;
    color: #0f1011;
    svg {
      margin-right: 1.2rem;
    }
  }
`;

const TermsAndConditions = ({ onNextStep }) => {
  return (
    <TermsAndConditionsRoot>
      <button onClick={() => onNextStep(0)}>
        <SmallLeftArrowIcon /> Back
      </button>
      <h1 className="terms-title">Privacy Policy</h1>
      <h2 className="terms-point">1. WHAT INFORMATION DO WE COLLECT?</h2>
      <p className="terms-desc" style={{ marginBottom: "2.8rem" }}>
        Personal information you disclose to us.
      </p>
      <p className="terms-text">
        In Short: We collect personal information that you provide to us such as
        name, address, contact information, passwords and security data, and
        payment information.
      </p>
      <p className="terms-text">
        We collect personal information that you voluntarily provide to us when
        registering at the Sites expressing an interest in obtaining information
        about us or our products and services, when participating in activities
        on the Sites or otherwise contacting us.
      </p>
      <p className="terms-text">
        The personal information that we collect depends on the context of your
        interactions with us and the Sites, the choices you make and the
        products and features you use. The personal information we collect can
        include the following:
      </p>
      <p className="terms-text">
        <strong>Name and Contact Data.</strong> We collect your first and last
        name, email address, postal address, phone number, and other similar
        contact data.
      </p>
      <p className="terms-text">
        All personal information that you provide to us must be true, complete
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>

      <p className="terms-desc">Information automatically collected</p>

      <p className="terms-text">
        In Short: Some information – such as IP address and/or browser and
        device characteristics – is collected automatically when you visit our
        Sites.
      </p>
      <p className="terms-text">
        We automatically collect certain information when you visit, use or
        navigate the Sites. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Sites and other technical information. This information is primarily
        needed to maintain the security and operation of our Sites, and for our
        internal analytics and reporting purposes.
      </p>
      <p className="terms-text">
        Like many businesses, we also collect information through cookies and
        similar technologies.
      </p>

      <p className="terms-desc">Information collected from other sources</p>

      <p className="terms-text">
        In Short: We may collect limited data from public databases, marketing
        partners, and other outside sources.
      </p>
      <p className="terms-text">
        We may obtain information about you from other sources, such as public
        databases, joint marketing partners, as well as from other third
        parties. Examples of the information we receive from other sources
        include: social media profile information; marketing leads and search
        results and links, including paid listings (such as sponsored links).
      </p>
      <p className="terms-text">
        The Site is intended for a U.S. audience. Any information you provide,
        including any personal information, will be transferred to, and
        processed by, a computer server located within the United States and
        will be handled in accordance with this Privacy Policy and U.S. law,
        which may be less stringent than the law where you reside.
      </p>

      <h2 className="terms-point">2. HOW DO WE USE YOUR INFORMATION?</h2>

      <p className="terms-text">
        In Short: We process your information for purposes based on legitimate
        business interests, the fulfillment of our contract with you, compliance
        with our legal obligations, and/or your consent.
      </p>
      <p className="terms-text">
        We use personal information collected via our Sites for a variety of
        business purposes described below. We process your personal information
        for these purposes in reliance on our legitimate business interests
        (“Business Purposes”), in order to enter into or perform a contract with
        you (“Contractual”), with your consent (“Consent”), and/or for
        compliance with our legal obligations (“Legal Reasons”). We indicate the
        specific processing grounds we rely on next to each purpose listed
        below.
      </p>
      <p className="terms-desc">
        We use the information we collect or receive:
      </p>
      <p className="terms-text">
        <strong>
          To facilitate account creation and logon process with your Consent.
        </strong>{" "}
        If you choose to link your account with us to a third party account
        *(such as your Google or Facebook account), we use the information you
        allowed us to collect from those third parties to facilitate account
        creation and logon process.
      </p>
      <p className="terms-text">
        <strong>
          To send you marketing and promotional communications for Business
          Purposes.
        </strong>{" "}
        We and/or our third party marketing partners may use the personal
        information you send to us for our marketing purposes, if this is in
        accordance with your marketing preferences. You can opt-out of our
        marketing emails at any time (see the “WHAT ARE YOUR PRIVACY RIGHTS”
        below).
      </p>
      <p className="terms-text">
        <strong>
          To send administrative information to you for Business Purposes.
        </strong>{" "}
        We may use your personal information to send you product, service and
        new feature information and/or information about changes to our terms,
        conditions, and policies.
      </p>
      <p className="terms-text">
        <strong>Fulfill and manage your orders for Contractual reasons.</strong>{" "}
        We may use your information to fulfill and manage your orders, payments,
        returns, and exchanges made through the Sites.
      </p>
      <p className="terms-text">
        <strong>To post testimonials with your Consent.</strong> We post
        testimonials on our Sites that may contain personal information. Prior
        to posting a testimonial, we will obtain your consent to use your name
        and testimonial. If you wish to update, or delete your testimonial,
        please contact us at recruiting@novalines.com and be sure to include
        your name, testimonial location, and contact information.
      </p>
      <p className="terms-text">
        <strong>Request Feedback for our Business Purposes.</strong> We may use
        your information to request feedback and to contact you about your use
        of our Sites.
      </p>
      <p className="terms-text">
        <strong>To protect our Sites for Business Purposes.</strong> We may use
        your information as part of our efforts to keep our Sites safe and
        secure (for example, for fraud monitoring and prevention).
      </p>

      <p className="terms-desc">
        To enforce our terms, conditions and policies for Business Purposes.
      </p>

      <p className="terms-text">
        <strong>
          To respond to legal requests and prevent harm for Legal Reasons.
        </strong>{" "}
        If we receive a subpoena or other legal request, we may need to inspect
        the data we hold to determine how to respond.
      </p>
      <p className="terms-text" style={{ marginBottom: "2.8rem" }}>
        <strong>For other Business Purposes.</strong> We may use your
        information for other Business Purposes, such as data analysis,
        identifying usage trends, determining the effectiveness of our
        promotional campaigns and to evaluate and improve our Sites, products,
        services, marketing and your experience.
      </p>

      <h2 className="terms-point">
        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
      </h2>

      <p className="terms-text">
        In Short: We only share information with your consent, to comply with
        laws, to protect your rights, or to fulfill business obligations.
      </p>
      <p className="terms-text">
        <strong>
          We only share and disclose your information in the following
          situations: Compliance with Laws.
        </strong>{" "}
        We may disclose your information where we are legally required to do so
        in order to comply with applicable law, governmental requests, a
        judicial proceeding, court order, or legal process, such as in response
        to a court order or a subpoena (including in response to public
        authorities to meet national security or law enforcement requirements).
      </p>
      <p className="terms-text">
        <strong>Vital Interests and Legal Rights.</strong> We may disclose your
        information where we believe it is necessary to investigate, prevent, or
        take action regarding potential violations of our policies, suspected
        fraud, situations involving potential threats to the safety of any
        person and illegal activities, or as evidence in litigation in which we
        are involved.
      </p>
      <p className="terms-text">
        <strong>
          Vendors, Consultants and Other Third-Party Service Providers.
        </strong>{" "}
        We may share your data with third party vendors, service providers,
        contractors or agents who perform services for us or on our behalf and
        require access to such information to do that work. Examples include:
        payment processing, data analysis, email delivery, hosting services,
        customer service and marketing efforts. We may allow selected third
        parties to use tracking technology on the Sites, which will enable them
        to collect data about how you interact with the Sites over time. This
        information may be used to, among other things, analyze and track data,
        determine the popularity of certain content and better understand online
        activity. Unless described in this Policy, we do not share, sell, rent
        or trade any of your information with third parties for their
        promotional purposes.
      </p>
      <p className="terms-text">
        <strong>Business Transfers.</strong> We may share or transfer your
        information in connection with, or during negotiations of, any merger,
        sale of company assets, financing, or acquisition of all or a portion of
        our business to another company.
      </p>
      <p className="terms-text">
        <strong>Business Partners.</strong> We may share your information with
        our business partners to offer you certain products, services or
        promotions.
      </p>
      <p className="terms-text">
        <strong>With your Consent.</strong> We may disclose your personal
        information for any other purpose with your consent.
      </p>

      <h2 className="terms-point">
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </h2>

      <p className="terms-text">
        In Short: We may use cookies and other tracking technologies to collect
        and store your information.
      </p>
      <p className="terms-text" style={{ marginBottom: "2.8rem" }}>
        <strong>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information.
        </strong>{" "}
        Specific information about how we use such technologies and how you can
        refuse certain cookies is set out in our Cookie Policy.
      </p>

      <h2 className="terms-point">5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

      <p className="terms-text">
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy policy unless otherwise required
        by law.
      </p>
      <p className="terms-text">
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy policy, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting or other legal requirements). No purpose in this policy will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us.
      </p>
      <p className="terms-text">
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize it, or, if this
        is not possible (for example, because your personal information has been
        stored in backup archives), then we will securely store your personal
        information and isolate it from any further processing until deletion is
        possible.
      </p>

      <h2 className="terms-point">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

      <p className="terms-text">
        In Short: We aim to protect your personal information through a system
        of organizational and technical security measures.
      </p>
      <p className="terms-text" style={{ marginBottom: "2.8rem" }}>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, please also remember that we cannot guarantee that the
        internet itself is 100% secure. Although we will do our best to protect
        your personal information, transmission of personal information to and
        from our Sites is at your own risk. You should only access the services
        within a secure environment.
      </p>

      <h2 className="terms-point">7. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

      <p className="terms-text">
        In Short: In some regions, such as the European Economic Area, you have
        rights that allow you greater access to and control over your personal
        information. You may review, change, or terminate your account at any
        time.
      </p>
      <p className="terms-text">
        In some regions (like the European Economic Area), you have certain
        rights under applicable data protection laws. These may include the
        right (i) to request access and obtain a copy of your personal
        information, (ii) to request rectification or erasure; (iii) to restrict
        the processing of your personal information; and (iv) if applicable, to
        data portability. In certain circumstances, you may also have the right
        to object to the processing of your personal information. To make such a
        request, please use the contact details provided below. We will consider
        and act upon any request in accordance with applicable data protection
        laws.
      </p>
      <p className="terms-text">
        If we are relying on your consent to process your personal information,
        you have the right to withdraw your consent at any time. Please note
        however that this will not affect the lawfulness of the processing
        before its withdrawal.
      </p>
      <p className="terms-text">
        If you are resident in the European Economic Area and you believe we are
        unlawfully processing your personal information, you also have the right
        to complain to your local data protection supervisory authority. You can
        find their contact details here:{" "}
        <a
          href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
          target="_blank"
          rel="noreferrer"
        >
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
      </p>

      <h2 className="terms-desc">Account Information</h2>

      <p className="terms-text">
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>

      <ul>
        <li>Log into your account settings and update your user account</li>
      </ul>

      <p className="terms-text">
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        some information may be retained in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with legal requirements.
      </p>
      <p className="terms-text">
        Cookies and similar technologies: Most Web browsers are set to accept
        cookies by default. If you prefer, you can usually choose to set your
        browser to remove cookies and to reject cookies. If you choose to remove
        cookies or reject cookies, this could affect certain features or
        services of our Sites. To opt-out of interest-based advertising by
        advertisers on our Sites visit{" "}
        <a
          href="http://www.aboutads.info/choices/"
          target="_blank"
          rel="noreferrer"
        >
          http://www.aboutads.info/choices/.
        </a>
      </p>
      <p className="terms-text">
        Opting out of email marketing: You can unsubscribe from our marketing
        email list at any time by clicking on the unsubscribe link in the emails
        that we send or by contacting us using the details provided below. You
        will then be removed from the marketing email list – however, we will
        still need to send you service-related emails that are necessary for the
        administration and use of your account. To otherwise opt-out, you may:
      </p>

      <ul style={{ marginBottom: "2.8rem" }}>
        <li style={{ marginBottom: "1.6rem" }}>
          Contact us using the contact information provided
        </li>
        <li>Access your account settings and update preferences</li>
      </ul>

      <h2 className="terms-desc">8. DO WE MAKE UPDATES TO THIS POLICY?</h2>

      <p className="terms-text">
        In Short: Yes, we will update this policy as necessary to stay compliant
        with relevant laws.
      </p>
    </TermsAndConditionsRoot>
  );
};

export default TermsAndConditions;
