import CustomButton from "components/CustomButton";
import styled from "styles";

const MessageError = styled.p`
  font-size: 1.2rem;
  color: var(--error-color);
`;

const SendButton = ({ children, error, ...props }) => {
  return (
    <>
      {error && (
        <MessageError style={{ margin: "1rem 0" }}>
          &#42; {error}
        </MessageError>
      )}
      <CustomButton {...props}>{children}</CustomButton>
    </>
  );
};

export default SendButton;
