import { Formik } from "formik";
import { getPhoneSchema } from "validations";

import { useSubmitForm } from "hooks/useSubmitform";

import { PhoneField } from "components/FormFields";
import SendButton from "components/SendButton";

import styled from "styles";
const EditPhoneStepRoot = styled.div`
  h2 {
    margin-bottom: 0.8rem;
  }
  p {
    margin-bottom: 3.8rem;
  }
  .form-row {
    margin-bottom: 7.1rem;
  }
`;

const EditPhoneStep = ({
  customerId,
  setCustomer,
  phoneNumber,
  setPhoneNumber,
  previousSlide,
}) => {
  const [{ loading, error }, handleSubmit] = useSubmitForm({
    method: "updatePhoneNumber",
    path: "/lead",
    callback: ({ phone }) => {
      setCustomer({ phone });
      setPhoneNumber(phone);
      previousSlide();
    },
  });

  return (
    <EditPhoneStepRoot>
      <h2 className="title">Edit phone number</h2>
      <p className="description">
        Make sure the phone number you entered is correct
      </p>

      <Formik
        initialValues={{
          phone: phoneNumber,
        }}
        validationSchema={getPhoneSchema()}
        onSubmit={({ phone }) => handleSubmit({ customerId, phone })}
      >
        {({ values, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <PhoneField
                name="phone"
                value={values.phone}
                placeholder="Phone"
                onChange={setFieldValue}
                onBlur={handleBlur}
              />
            </div>

            <SendButton
              error={error}
              disabled={loading}
              type="submit"
              conditionalClasses={loading ? "inactive" : ""}
            >
              Confirm
            </SendButton>
          </form>
        )}
      </Formik>
    </EditPhoneStepRoot>
  );
};

export default EditPhoneStep;
