import { useEffect, useState } from "react";

const ResendCode = ({ onResendCode }) => {
  const [seconds, setSeconds] = useState(5);
  const handleClick = () => {
    onResendCode();
    setSeconds(5);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds === 0) {
        clearInterval(interval);
      } else {
        setSeconds((seconds) => seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const disabled = seconds > 0;
  return (
    <>
      {seconds ? `Resend code in ${seconds} sec.` : null} Didn't get a
      verification code?{" "}
      <button
        disabled={disabled}
        onClick={handleClick}
        className={disabled ? "disabled" : null}
      >
        Resend code
      </button>
    </>
  );
};

export default ResendCode;
