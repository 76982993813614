import { useField } from "formik";
import { FieldError, InputFieldWrapper, Input } from "./FormFields.styles";

const InputField = ({ name, ...rest }) => {
  const [field, meta] = useField(name);

  return (
    <InputFieldWrapper>
      <Input
        className={meta.touched && meta.error ? "input-error" : ""}
        {...field}
        {...rest}
      />
      {meta.touched && meta.error && <FieldError>{meta.error}</FieldError>}
    </InputFieldWrapper>
  );
};

export default InputField;
