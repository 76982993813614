import { useState } from "react";
import api from "services";

export const useFetch = ({ method, path }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const fetchData = async (values = {}) => {
    setLoading(true);
    api[method](path, values)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  return [{ loading, error, data }, fetchData];
};
