import { useState } from "react";

import { AnimatePresence } from "framer-motion";
import AnimatedScreen from "components/AnimatedScreen";
import EditPhoneStep from "./EditPhoneStep";
import VerificationCodeStep from "./VerificationCodeStep";

const SecondScreen = ({ customer, setCustomer, onNextStep }) => {
  const { phone, customerId } = customer;
  const [slide, setSlide] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState(phone);
  const handleNextSlide = () => setSlide((slide) => slide + 1);
  const handlePrevSlide = () => setSlide((slide) => slide - 1);

  let screen = null;
  switch (slide) {
    case 0:
      screen = (
        <VerificationCodeStep
          customerId={customerId}
          onNextSlide={handleNextSlide}
          onNextStep={onNextStep}
        />
      );
      break;
    case 1:
      screen = (
        <EditPhoneStep
          customerId={customerId}
          setCustomer={setCustomer}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          previousSlide={handlePrevSlide}
        />
      );
      break;
    default:
      break;
  }

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <AnimatedScreen
          direction={slide === 0 ? "right" : "left"}
          screenKey={slide}
        >
          {screen}
        </AnimatedScreen>
      </AnimatePresence>
    </>
  );
};

export default SecondScreen;
