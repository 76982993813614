import CustomButton from "components/CustomButton/CustomButton";
import styled from "styles";
import img from "assets/images/cognito.png";
const ConditionalScreenRoot = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .flex-title {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 120%;
    z-index: 10;
    div {
      margin-right: 0.4rem;
      padding-left: 0.6rem;
      padding-right: 0.6rem;
      font-size: 5rem;
      color: #fff;
      font-weight: 700;
      line-height: 120%;
      background: #c93030;
      border-radius: 4px;
    }
  }
  p {
    margin-bottom: 3rem;
    font-size: 1.9rem;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    color: #fff;
  }
  .img-container {
    flex: 1;
    position: relative;
    width: calc(100% + 3.2rem);
    left: -1.6rem;
    background: url(${img}) no-repeat center center;
    background-size: cover;
    div {
      z-index: -1;
      bottom: 100%;
      position: absolute;
      width: 100%;
      height: 9.7rem;
      background: linear-gradient(rgba(0, 0, 0, 0) 1.35%, #1f1f1f 98.65%);
    }
  }

  a {
    position: absolute;
    left: 0;
    bottom: 2.2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.6rem;
    font-weight: 700;

    text-transform: uppercase;
    color: var(--font-white-color);
    background-color: ${(props) => `${props.theme.mainColor}`};
    border-radius: 0.4rem;
  }
`;

const ConditionalScreen = () => {
  return (
    <ConditionalScreenRoot>
      <div className="flex-title">
        <div>300</div>
        ACTIVE <br /> FAKE BROKERS
      </div>
      <p>Protect your Business from FRAUD!</p>

      <div className="img-container">
        <div></div>
      </div>

      <CustomButton
        as="a"
        href={require("assets/docs/active_fake_brokers.pdf")}
        download
      >
        Download
      </CustomButton>
    </ConditionalScreenRoot>
  );
};

export default ConditionalScreen;
