import styled from "styles";

const Button = styled.button`
  transition: 0.2s ease-in-out;

  &.primary-contained-btn {
    color: var(--font-white-color);
    background-color: ${(props) => `${props.theme.mainColor}`};
    border-radius: 0.4rem;
  }
  &.dark-contained-btn {
    color: var(--font-white-color);
    background-color: #212121;
    border-radius: 0.4rem;
  }
  &.dark-reverse-btn {
    font-size: 1.4rem;
    color: #212121;
    border: 1.5px solid #212121;
    border-radius: 0.4rem;
  }
  &.inactive {
    background-color: #d7d7d7;
  }
`;
const CustomButton = ({
  children,
  variant = "primary-contained-btn",
  conditionalClasses = false,
  ...props
}) => {
  return (
    <Button
      className={`${variant} ${conditionalClasses ? conditionalClasses : ""}`}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;