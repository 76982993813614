import PhoneInput from "react-phone-input-2";
import { useField } from "formik";
import { FieldError, PhoneInputFieldWrapper } from "./FormFields.styles";

const PhoneField = ({ name, onChange, country = "us", ...rest }) => {
  const [_, meta] = useField(name);
  return (
    <PhoneInputFieldWrapper>
      <PhoneInput
        containerClass="phone-container"
        inputClass={meta.touched && meta.error ? "input-error" : ""}
        onChange={(value, country, e, formattedValue) => {
          onChange(name, value);
        }}
        country={country}
        {...rest}
        inputProps={{
          name,
        }}
      />
      {meta.touched && meta.error && <FieldError>{meta.error}</FieldError>}
    </PhoneInputFieldWrapper>
  );
};

export default PhoneField;
