import { useState } from "react";

import { useSubmitForm } from "hooks/useSubmitform";
import { useFetch } from "hooks/useFetch";

import ReactInputVerificationCode from "react-input-verification-code";
import SendButton from "components/SendButton";

import styled from "styles";
import ResendCode from "components/ResendCode";
const VerificationStepRoot = styled.div`
  .link > span {
    font-weight: 600;
    text-decoration: underline;
    color: ${(props) => `${props.theme.mainColor}`};
    cursor: pointer;
  }

  .verification-input-wrap {
    overflow: hidden;
    --ReactInputVerificationCode-itemWidth: 4.6rem;
    --ReactInputVerificationCode-itemHeight: 4.6rem;
    --ReactInputVerificationCode-itemSpacing: 1rem;
    margin-bottom: 4.8rem;
    .ReactInputVerificationCode__input {
      font-size: 1.6rem;
      outline: none;
      border: none;
      opacity: 0.1;
      color: transparent;
      background-color: transparent;
      caret-color: transparent;
    }
    .ReactInputVerificationCode__input:focus {
      outline: none;
    }
    .ReactInputVerificationCode__item {
      font-size: 2rem;
      font-weight: 500;
      border-bottom: 1.5px solid transparent;
      border-radius: 0;
      color: #0e0f10;
      background: #f3f3f3;
      transition: 0.2s ease-in;
    }
    .ReactInputVerificationCode__item,
    .ReactInputVerificationCode__item.is-active {
      box-shadow: none;
    }
    .ReactInputVerificationCode__item.is-active {
      border-color: ${(props) => `${props.theme.mainColor}`};
    }
    @media screen and (min-width: 375px) {
      --ReactInputVerificationCode-itemWidth: 5.6rem;
      --ReactInputVerificationCode-itemHeight: 5.6rem;
    }
  }

  & > .resend-code-wrapper {
    margin-top: 1.6rem;
    text-align: center;
    font-size: 1.4rem;
    line-height: 20px;
    color: #666666;

    button {
      display: inline;
      width: auto;
      text-decoration: underline;
      font-weight: 500;
      color: var(--font-primary-color);
    }
    .disabled {
      color: #eee;
    }
  }
`;

const VerificationStep = ({ customerId, onNextSlide, onNextStep }) => {
  const [code, setCode] = useState("");
  const [{ loading, error }, submitCode] = useSubmitForm({
    method: "checkSmsCode",
    path: "/code/check",
    callback: onNextStep,
  });
  const [, getVerificationCode] = useFetch({
    method: "getSmsCode",
    path: "/code",
  });

  const handleSubmitCode = () => {
    setCode("");
    submitCode({ code, customerId });
  };
  const handleGetVerificationCode = () => getVerificationCode({ customerId });

  const inactiveButton = loading || code.length < 5;
  return (
    <VerificationStepRoot>
      <p className="text" style={{ marginBottom: "0.4rem" }}>
        Congratulations!
      </p>
      <p className="description" style={{ marginBottom: "2rem" }}>
        You have taken the first step to saving your capital from scammers!
      </p>
      <h2 className="title">Verify your phone number</h2>
      <p className="description" style={{ marginBottom: "0.8rem" }}>
        We are so excited to meet you, please use the code from your text
        messages for the final confirmation of the meeting.
      </p>
      <p className="description link" style={{ marginBottom: "2.8rem" }}>
        Wrong number? <span onClick={onNextSlide}>Edit the number</span>
      </p>

      <div className="verification-input-wrap">
        <ReactInputVerificationCode
          onChange={(code) => setCode(code)}
          value={code}
          length={5}
          placeholder=""
        />
      </div>

      <SendButton
        error={error}
        disabled={inactiveButton}
        onClick={handleSubmitCode}
        conditionalClasses={inactiveButton ? "inactive" : ""}
        id="step-2"
      >
        Confirm
      </SendButton>

      <div className="resend-code-wrapper">
        <ResendCode onResendCode={handleGetVerificationCode} />
      </div>
    </VerificationStepRoot>
  );
};

export default VerificationStep;
