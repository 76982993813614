import { useState } from "react";
import api from "services";

export const useSubmitForm = ({ method, path, callback }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (values) => {
    if (error) setError(null);
    setLoading(true);

    for (let key in values) {
      if (typeof values[key] === "string") {
        values[key] = values[key].trim();
      }
    }

    api[method](path, values)
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  return [{ loading, error }, handleSubmit];
};
